import request from "@/utils/request";

/**
 * 取得會員發票設定
 *
 * @export
 * @param {*} [data={}]
 * @return {*}
 */
export function getInvoice(data = {}) {
  return request.post("/member/getInvoice", data);
}
/**
 * 查詢會員信息
 *
 * @export
 * @param {*} [data={}]
 * @return {*}
 */
export function getInfo(data = {}) {
  return request.post("/member/getInfo", data);
}
/**
 * 驗證交易密碼
 *
 * @export
 * @param {*} [data={}]
 * paymentPassword	string 交易密码
 * @return {*}
 */
export function verifyPaymentPassword(data = {}) {
  return request.post("/member/verifyPaymentPassword", data);
}
/**
 * 忘記交易密碼
 *
 * @export
 * @param {*} [data={}]
 * @return {*}
 */
export function forgetPaymentPassword(data = {}) {
  return request.post("/member/forgetPaymentPasswordV2", data);
}

/**
 * 刷新token
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function refreshToken(data) {
  return request.post("/member/tokenUpdate", data, {
    skipQueue: true
  });
}

export function getTransPwd() {
  return request.post("/member/getTransPwd");
}

export function getTransPwdFirst() {
  return request.post("/member/getTransPwdFirst");
}

export function getTransPwdStatus() {
  return request.post("/member/getTransPwdStatus");
}

export function setTransPwdStatus(status = false) {
  return request.post("/member/setTransPwdStatus", {
    useTransPwd: status
  });
}
export function setTransPwdFirst() {
  return request.post("/member/setTransPwdFirst");
}
