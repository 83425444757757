import getDeviceInfo from "@/utils/deviceInfo";
import request from "@/utils/request";

const SEQ_MAP = {
  uat: {
    pick_not_bind: "df1EQxF9lQx",
    pick_bind: "df1EQxF9lQ6",
    pointShop_bind: "df1EQxF9lQX",
    pointShop_not_bind: "df1EQxF9lQw"
  },
  prod: {
    pick_not_bind: "df1EQxFyF6F",
    pick_bind: "df1EQxFyF3Y",
    pointShop_bind: "df1EQxFyF3P",
    pointShop_not_bind: "df1EQxFyF3F"
  }
};

function getSysAvailableToken() {
  return request.get("/common/getSysAvailableToken");
}

function saveTag(seq) {
  return request.post("/common/saveTag", { seq });
}

export default {
  SEQ_MAP,
  getSysAvailableToken,
  saveTag
};
