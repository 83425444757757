import request from "@/utils/request";
import JSEncrypt from "jsencrypt";
import { generateRandomString } from "@/utils/utils";
import store from "@/store";

function testCoolbeResponseCode(
  data = {
    code: "",
    data: {},
    http_code: 400,
    msg: ""
  }
) {
  return request.post("/mtelapi/v1/test/coolbe/responseCode", data);
}

function addLog({ status = "ERROR", code = "", info = {} }) {
  return request.post("/mtelapi/v1/log/create", `[FRONTEND][${status}${code ? `:${code}` : ""}] ${JSON.stringify(info)}`);
}

export default {
  testCoolbeResponseCode,
  addLog
};
